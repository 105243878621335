.feedback-dialog {
  .MuiDialog-container {
    .MuiPaper-root {
      max-width: 488px;
      padding: 0;
      .MuiDialogContent-root {
        padding: 30px;
      }
    }
  }
  .MuiDialogContent-root {
    width: auto !important;
    box-sizing: border-box;
    .MuiTypography-root {
      margin: 0;
    }
    .modal-title {
      width: 100%;
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
      text-align: center;
      margin: 0 0 6px 0px;
      + div {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        margin: 0 auto 15px;
        max-width: 100%;
        a {
          color: var(--primary-dark-color);
          text-decoration: underline;
        }
      }
    }
    .custom-rating {
      > span {
        > span {
          margin: 0 5px;
        }
      }
      .star {
        width: 52px !important;
        height: 52px !important;
        margin: 0 4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .model-body {
    max-width: 440px;
    margin: 0 auto;
    .textbox-label {
      color: var(--field-label-color) !important;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      margin: 0 0 4px 0 !important;
    }
    .issueType {
      margin: 0 auto;
      max-width: 285px;
      label {
        color: var(--field-label-color) !important;
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        margin: 0 0 4px 0 !important;
      }
      > div {
        margin-bottom: 15px;
      }
    }
    .rating-box {
      text-align: center;
      margin: 10px 0 20px;
      .sub-header-primary {
        color: var(--field-label-color);
        margin: 0 0 4px 0;
      }
    }
    .sub-header-primary {
      margin: 0;
    }
    .about-box {
      margin: 0 0 20px;
      > div {
        margin-bottom: 0;
      }
      label.form-label {
        margin-top: 0;
        margin-bottom: 7px;
      }
      .MuiOutlinedInput-multiline {
        padding: 0;
        margin: 0;
      }
      .MuiInputBase-root {
        margin-bottom: 0;
      }
      fieldset {
        display: none;
      }
      .MuiInputBase-input {
        text-align: left;
        border-radius: 20px !important;
        cursor: default;
      }
    }
    .fileupload-custom {
      .dropzone {
        height: 160px;
        img {
          height: auto;
        }
        .upload-img {
          &.uploaded {
            img {
              max-height: 160px;
              width: auto;
            }
          }
        }
      }
    }
    .friend-refer {
      max-width: 380px;
      margin: 0 auto;
      .sub-header-primary.title {
        color: var(--field-label-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        display: block;
        width: 100%;
        word-break: break-word;
        margin: 0 0 15px 0;
      }
      ul {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        justify-content: space-between;
        li.point {
          height: 30px;
          width: 30px;
          border-radius: 100px;
          line-height: 30px;
          color: #000c3d;
          font-family: "TTCommons-Regular";
          font-size: 18px;
          letter-spacing: 0;
          text-align: center;
          margin: 0 0 6px;
          background: #f1f4f7;
          padding-top: 2px;
          &.active {
            background: #000c3d;
            color: #fff;
          }
        }
      }
    }
    .like {
      display: flex;
      justify-content: space-between;
      margin: 4px 0 30px;
      .sub-header-primary {
        margin: 0;
        font-size: 15px;
        line-height: 17px;
      }
      .text-right {
        text-align: right;
      }
      .text-left {
        text-align: left;
      }
    }
    .file-attch {
      display: flex;
      margin: 0 0 25px;
      color: #f76155;
      flex-wrap: wrap;
      justify-content: center;
      p {
        width: 100%;
        text-align: center;
        margin: 5px 0 0 0;
      }
      .btn-upload {
        margin: 0 0 0 10.5px;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
        border-bottom: 1px solid #f76155;
      }
    }
    .feedback-reply {
      margin: 10px 0px 15px 0px;
      + .btn {
        margin-bottom: 0;
      }
    }
    .got-it-btn {
      button {
        background-color: #f76155;
        color: #ffffff;
        width: 124px;
        max-width: 256px;
        height: auto;
        padding-top: 0.75rem;
        border-radius: 100px;
        font-size: 22px;
        font-family: TTCommons-Medium;
        line-height: 21px;
        text-transform: none;
        box-shadow: none;
        height: auto !important;
      }
    }
    .btn {
      text-align: center;
      margin: 0 0 30px;
      padding-bottom: 0;
      button {
        box-shadow: none;
        border-radius: 45px;
        height: 60px;
        font-size: 18px;
        line-height: 17px;
        padding: 0.75rem 30px 8px;
        &.got-it {
          height: 30px;
          width: 124px;
          color: #ffffff;
          font-family: "TTCommons-Regular";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 17px;
        }
      }
    }
    .text-cancel {
      color: var(--gray-color);
      font-family: "TTCommons-Medium";
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      text-decoration: none;
      border-bottom: 2px solid var(--gray-color);
      cursor: pointer;
    }
    .btn-contact-us {
      text-align: center;
      margin: 30px 0 30px;
      button {
        background-color: #0030b5;
        color: #ffffff;
        width: 100%;
        max-width: 256px;
        height: auto;
        min-height: 50px;
        padding-top: 0.75rem;
        border-radius: 100px;
        font-size: 18px;
        font-family: TTCommons-Medium;
        line-height: 21px;
        text-transform: none;
        box-shadow: none;
        margin-bottom: 0;
        padding: 15px 16px 10px;
      }
    }
    .btn-submit-feedback {
      text-align: center;
      margin: 0 0 0;
      button {
        background-color: #0030b5;
        color: #ffffff;
        width: 100%;
        max-width: 256px;
        height: auto;
        min-height: 50px;
        padding-top: 0.75rem;
        border-radius: 100px;
        font-size: 18px;
        font-family: TTCommons-Medium;
        line-height: 21px;
        text-transform: none;
        box-shadow: none;
        padding: 15px 16px 10px;
      }
    }
    .no-margin {
      button {
        margin: 0px !important;
      }
    }
  }
}
